<template>
  <div id="main">
    <DFSHeader active="比对" />
    <AlignContent />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import AlignContent from './components/AlignContent';

export default {
  name: 'Align',
  components: {
    DFSHeader, DFSFooter, AlignContent
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>

</style>