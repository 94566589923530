import service from '@/utils/service';


export const fastaSampleData = `\
>VSL-S-00000027-539A
AACTTTATACTTTATTTTTGGAGCTTGAGCAGGTATAGTGGGAAC\
TTCATTAAGAATTCTTATTCGAGCAGAATTAGGTCATCCTGGTGCA\
TTAATTGGTGATGATCAAATTTATAATGTAATTGTTACAGCTCACGC\
TTTTATTATAATTTTTTTTATAGTAATACCAATTATAATTGGAGGATT\
TGGAAATTGACTAGTACCAATTATATTAGGAGCTCCAGATATAGCTTT\
TCCTCGAATAAATAATATAAGTTTTTGACTTTTACCCCCAGCATTAACA\
TTACTTCTAGTAAGTAGTATAGTAGAAAACGGAGCTGGAACAGGATGAAC\
TGTTTATCCTCCTTTATCTTCTAATATTGCTCATGGAGGAGCTTCTGTTGA\
TTTAGCTATTTTTTCTCTACATTTAGCTGGAATTTCTTCAATTTTAGGAGCA\
GTAAATTTTATTACTACAGTTATTAATATACGATCTACAGGTATTACATTTGA\
TCGTATACCTTTATTTGTATGATCAGTAGTAATTACTGCTTTACTTTTATTATT\
ATCATTACCTGTACTGGCTGGAGCAATTACTATATTATTAACTGACCGAAATATT\
AATACTTCATTCTTTGATCCCGCTGGAGGAGGAGATCCTATTCTATATCAACATTTATTT
>VSL-S-00000027-539B
AACTTTATACTTTATTTTTGGAGCTTGAGCAGGTATAGTGGGAACTTCATTAAG\
AATTCTTATTCGAGCAGAATTAGGTCATCCTGGTGCATTAATTGGTGATGATCAA\
ATTTATAATGTAATTGTTACAGCTCACGCTTTTATTATAATTTTTTTTATAGTAA\
TACCAATTATAATTGGAGGATTTGGAAATTGACTAGTACCAATTATATTAGGAGC\
TCCAGATATAGCTTTTCCTCGAATAAATAATATAAGTTTTTGACTTTTACCCCCAG\
CATTAACATTACTTCTAGTAAGTAGTATAGTAGAAAACGGAGCTGGAACAGGATGA\
ACTGTTTATCCTCCTTTATCTTCTAATATTGCTCATGGAGGAGCTTCTGTTGATTTA\
GCTATTTTTTCTCTACATTTAGCTGGAATTTCTTCAATTTTAGGAGCAGTAAATTTTA\
TTACTACAGTTATTAATATACGATCTACAGGTATTACATTTGATCGTATACCTTTATT\
TGTATGATCAGTAGTAATTACTGCTTTACTTTTATTATTATCATTACCTGTACTGGCTG\
GAGCAATTACTATATTATTAACTGACCGAAATATTAATACTTCATTCTTTGATCCCGCTG\
GAGGAGGAGATCCTATTCTATATCAACATTTATTT\
`;


export const basesOnlySampleData = `\
AACTTTATACTTTATTTTTGGAGCTTGAGCAGGTATAGTGGGAAC\
TTCATTAAGAATTCTTATTCGAGCAGAATTAGGTCATCCTGGTGCA\
TTAATTGGTGATGATCAAATTTATAATGTAATTGTTACAGCTCACGC\
TTTTATTATAATTTTTTTTATAGTAATACCAATTATAATTGGAGGATT\
TGGAAATTGACTAGTACCAATTATATTAGGAGCTCCAGATATAGCTTT\
TCCTCGAATAAATAATATAAGTTTTTGACTTTTACCCCCAGCATTAACA\
TTACTTCTAGTAAGTAGTATAGTAGAAAACGGAGCTGGAACAGGATGAAC\
TGTTTATCCTCCTTTATCTTCTAATATTGCTCATGGAGGAGCTTCTGTTGA\
TTTAGCTATTTTTTCTCTACATTTAGCTGGAATTTCTTCAATTTTAGGAGCA\
GTAAATTTTATTACTACAGTTATTAATATACGATCTACAGGTATTACATTTGA\
TCGTATACCTTTATTTGTATGATCAGTAGTAATTACTGCTTTACTTTTATTATT\
ATCATTACCTGTACTGGCTGGAGCAATTACTATATTATTAACTGACCGAAATATT\
AATACTTCATTCTTTGATCCCGCTGGAGGAGGAGATCCTATTCTATATCAACATTTATTT\
`;


export function checkSeq(seq) {
  return service({
    method: 'post',
    url: '/v1/aligns/check/',
    data: {
      seq
    }
  });
}


export function getSeqDetail(slug) {
  return service({
    method: 'get',
    url: '/v1/seqs/' + slug + '/'
  });
}

export function create(seq) {
  return service({
    method: 'post',
    url: '/v1/seqs/',
    data: seq
  });
}

export function destroy(slug) {
  return service({
    method: 'delete',
    url: `/v1/seqs/${slug}/`
  });
}

export function update(seq) {
  var data = { ...seq };
  const slug = data.slug;
  delete data.slug;
  return service({
    method: 'patch',
    url: `/v1/seqs/${slug}/`,
    data: data
  });
}

export function list(species) {
  return service({
    method: 'get',
    url: `/v1/seqs/?species=${species}`
  });
}