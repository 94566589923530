<template>
  <div id="main-content">
    <a-spin :spinning="running" tip="任务运行中，请不要刷新页面。" size="large">
      <a-card title="比对">
        <p>通过序列比对进行物种搜索，支持格式：</p>
        <ul>
          <li>
            多条序列：必须符合fasta格式
            （<a @click="setSampleData('fasta')">查看示例</a>）。
          </li>
          <li>
            单条序列：当序列不是fasta格式，文本框内所有序列会被作为同一条序列
            （<a @click="setSampleData('bases-only')">查看示例</a>）。
          </li>
        </ul>
        <p>请输入序列：</p>
        <a-textarea :rows="20" v-model="seq" class="seq" />
        <a-button type="primary" @click="onSubmit" :disabled="!seq">
          开始比对
        </a-button>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import {createAlign, getAlignStatus} from '@/services/align';
import {fastaSampleData, basesOnlySampleData, checkSeq} from '@/services/seq';

export default {
  name: 'AlignContent',
  data() {
    return {
      running: false,
      seq: '',
      taskId: '',
      timer: null
    }
  },
  methods: {
    setSampleData(type) {
      if (type === 'fasta') {
        this.seq = fastaSampleData;
      } else {
        this.seq = basesOnlySampleData;
      }
    },
    updateStatus() {
      if (!this.running) {
        return;
      }
      if (!this.taskId) {
        return;
      }
      getAlignStatus(this.taskId).then(res => {
        const status = res.data.data.status;
        if (status === 1) {
          this.$router.push('/aligns/' + this.taskId);
        }
        if (status === -1) {
          this.running = false;
          this.$message.error('任务运行失败。');
        }
      });
    },
    onSubmit() {
      checkSeq(this.seq).then(res => {
        if (this.seq.length <= 80) {
          this.$message.error('您输入的序列太短，请至少输入30个碱基。');
          return;
        }
        if (! res.data.data.valid) {
          this.$message.error('您输入的序列含有非法字符。');
          return;
        }
        this.running = true;
        createAlign(this.seq).then(res => {
          this.taskId = res.data.data.task_id;
          this.timer = setInterval(this.updateStatus, 3000);
        });
      });
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style lang="stylus" scoped>
#main-content
  padding-top: 1rem

  .seq
    margin-bottom: 1rem
</style>